import * as React from "react";
import { Statistic, Tag, Typography } from "antd";
import { getAmountToInvoice, IHour } from "../models/Hour";

interface IProps {
    hour: IHour;
}

export const HourSummary: React.FC<IProps> = (props) => (
    <div
        style={{
            minWidth: "600px",
        }}
    >
        <Typography.Title level={4}>
            {props.hour.key} - {props.hour.summary}
        </Typography.Title>
        <p>
            Bestaande factuurnummers:{" "}
            {props.hour.invoiceNrs.map((invoiceNr) => (
                <Tag key={invoiceNr}>{invoiceNr}</Tag>
            ))}
        </p>
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "2em",
            }}
        >
            <Statistic
                title="Al gefactureerde uren"
                value={props.hour.invoicedAmount}
                precision={2}
            />

            <Statistic
                title="Ingeschatte uren"
                value={props.hour.totalEstimateHours}
            />
            <Statistic
                title="Bestede uren"
                value={props.hour.totalSpendHours}
            />
            <Statistic
                title="Nog te besteden uren"
                value={props.hour.totalRemainingHours}
            />
            <Statistic
                title="Te factureren"
                value={getAmountToInvoice(props.hour)}
            />
        </div>
    </div>
);
