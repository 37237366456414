import * as React from "react";
import { useState } from "react";
import { Button, Layout, Space, Table, Typography } from "antd";
import { useEffectOnce } from "../../../helpers/useEffectOnce";
import { licenseService } from "../service";
import { IDebtor } from "../models/Debtor";
import { Toolbar } from "../../../shared/components/Toolbar";
import { ModalCreateLicenseInvoice } from "../components/ModalCreateLicenseInvoice";
import { ModalDebtorInfo } from "../components/ModalDebtorInfo";

interface IProps {}

export const LicenseScreen: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(true);
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    const [selected, setSelected] = useState<IDebtor[]>([]);

    const [showLicenseModal, setShowLicenseModal] = useState<number[]>([]);
    const [showInfoModal, setShowInfoModal] = useState<number | null>(null);

    const getDebtors = () => {
        setLoading(true);
        return licenseService
            .listDebtors()
            .then((newDebtors) => {
                setDebtors(newDebtors.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    useEffectOnce(() => {
        getDebtors();
    });

    return (
        <>
            {showLicenseModal.length !== 0 && (
                <ModalCreateLicenseInvoice
                    debtorNumbers={showLicenseModal}
                    onClose={() => setShowLicenseModal([])}
                />
            )}

            {showInfoModal !== null && (
                <ModalDebtorInfo
                    debtorNumber={showInfoModal}
                    onClose={() => setShowInfoModal(null)}
                />
            )}

            <Layout.Content style={{ margin: "1em 2em 2em 2em" }}>
                <div>
                    <Typography.Title level={3} style={{ textAlign: "left" }}>
                        Debiteuren
                    </Typography.Title>
                </div>

                <Table<IDebtor>
                    dataSource={debtors}
                    pagination={false}
                    loading={loading}
                    size="small"
                    style={{ paddingBottom: "6em" }}
                    rowSelection={{
                        selectedRowKeys: selected.map(
                            (item) => item.debtorNumber
                        ),
                        onChange: (selectedRowKeys) => {
                            console.log();
                            setSelected(
                                debtors.filter((hour) =>
                                    selectedRowKeys.includes(hour.debtorNumber)
                                )
                            );
                        },
                    }}
                    rowKey="debtorNumber"
                    columns={[
                        {
                            key: "id",
                            title: "Debiteurnummer",
                            dataIndex: "debtorNumber",
                        },
                        {
                            key: "id",
                            title: "Naam",
                            dataIndex: "name",
                        },
                    ]}
                />

                <Toolbar>
                    <Space>
                        <Button
                            type="primary"
                            disabled={selected.length === 0}
                            onClick={() =>
                                setShowLicenseModal(
                                    selected.map(
                                        (debtor) => debtor.debtorNumber
                                    )
                                )
                            }
                        >
                            Maak licentiefactuur
                        </Button>

                        <Button
                            disabled={selected.length !== 1}
                            onClick={() =>
                                setShowInfoModal(selected[0].debtorNumber)
                            }
                        >
                            Bekijk info
                        </Button>
                    </Space>
                </Toolbar>
            </Layout.Content>
        </>
    );
};
