import { getAxios } from "../../helpers/axiosClient";
import { IHour } from "./models/Hour";
import * as qs from "qs";
import { message } from "antd";
import { IResponse } from "../../helpers/response";
import { IPdfTemplate } from "./models/PdfTemplate";
import { dicToSnake } from "../../helpers/dictHelpers";
import { Moment } from "moment";
import { DateFormat } from "../../shared/constants";

interface ITicket {
    ticket: string;
    amount: number;
    unitPriceExcl: number;
    pdfTemplateId: number | null;
}

export class HourService {
    static getHour = (key: string) => {
        return getAxios()
            .get<IHour>(`/api/hours/${key}/`)
            .then((res) => res.data);
    };

    static getHours = (filters?: object): Promise<IResponse<IHour>> => {
        return getAxios()
            .get(
                `/api/hours/${qs.stringify(filters, {
                    addQueryPrefix: true,
                    arrayFormat: "repeat",
                })}`
            )
            .then((res) => res.data)
            .catch(() =>
                message.error("Er ging iets fout bij het ophalen van de uren.")
            );
    };

    static downloadPdf = (
        keys: string[],
        pdfTemplateId: number | null = null
    ) =>
        window.open(
            `/api/hours/pdf/${qs.stringify(
                dicToSnake({ keys, pdfTemplateId }),
                { addQueryPrefix: true, arrayFormat: "repeat" }
            )}`
        );

    static invoice = (tickets: ITicket[], invoiceNr: string) => {
        return getAxios()
            .post(`/api/hours/invoice/`, {
                invoice_nr: invoiceNr,
                tickets,
            })
            .then((res) => {
                message.success("Succesvol gefactureerd!");
                return res;
            })
            .catch((error) => {
                message.error("Er ging iets fout bij het factureren.");
                throw error;
            });
    };

    static export(data: {
        pdfTemplateId: number | null;
        startDate: Moment | null;
        endDate: Moment | null;
        unitPriceExcl: number;
        tickets: string[];
    }) {
        return getAxios()
            .post<{
                hours: number;
                invoice: { identifier: string; invoiceCode: string };
            }>(`/api/hours/export/`, {
                ...data,
                startDate: data.startDate
                    ? data.startDate.format(DateFormat.DATE_TO_BACKEND)
                    : null,
                endDate: data.endDate
                    ? data.endDate.format(DateFormat.DATE_TO_BACKEND)
                    : null,
            })
            .then((res) => {
                message.success("Succesvol geexporteerd!");
                return res;
            })
            .catch((error) => {
                message.error("Er ging iets fout bij het exporteren.");
                throw error;
            });
    }

    static sendInvoice(invoiceCode: string) {
        return getAxios()
            .post<{ invoice: { invoiceCode: string } }>(
                `/api/hours/send_invoice/`,
                {
                    invoice_code: invoiceCode,
                }
            )
            .then((res) => {
                message.success("Succesvol verstuurd!");
                return res;
            })
            .catch((error) => {
                message.error("Er ging iets fout bij het versturen.");
                throw error;
            });
    }

    static getPdfTemplates() {
        return getAxios()
            .get<IPdfTemplate[]>(`/api/hours/pdf_templates/`)
            .then((res) => res.data);
    }

    static getDefaultRate(hour: IHour) {
        return getAxios()
            .get<{ rate: number }>(
                `/api/hours/default_rate/?debit_number=${hour.organisation.debitNumber}&issue_type=${hour.issueType}`
            )
            .then((res) => res.data.rate);
    }

    static downloadHourLogs(ncsTicket: string, start: Moment, end: Moment) {
        window.open(
            `/api/hours/hour_logs/?ticket=${ncsTicket}&start=${start.format(
                DateFormat.DATE_TO_BACKEND
            )}&end=${end.format(DateFormat.DATE_TO_BACKEND)}`
        );
    }
}
