import * as React from "react";
import { AuthService } from "../authService";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context/GlobalContextProvider";
import { useEffectOnce } from "../../../helpers/useEffectOnce";

interface IProps {
    children: React.ReactElement;
}

export const AuthGuard: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const globalContext = React.useContext(GlobalContext);

    useEffectOnce(() => {
        AuthService.getCurrentUser().then((user) => {
            globalContext.setUser(user);
            if (!user) {
                navigate("/login");
            }
        });
    });

    if (!globalContext.user) {
        return null;
    }

    return props.children;
};
