import axios from "axios";
import { IUser } from "./models/User";
import { getAxios } from "../../helpers/axiosClient";
import { message } from "antd";

const getCookie = (key: string): string | undefined => {
    const match = document.cookie.match(new RegExp("(^| )" + key + "=([^;]+)"));
    return match ? match[2] : undefined;
};

export class AuthService {
    static getToken = (): string => String(localStorage.getItem("token") || "");
    static setToken = (token: string) => localStorage.setItem("token", token);

    static getCurrentUser = (): Promise<IUser | null> => {
        return getAxios()
            .get<{
                user: IUser | null;
                csrfToken: string;
            }>("/api/users/current/")
            .then((res) => {
                if (res.data.csrfToken) {
                    axios.defaults.headers.common["X-Csrftoken"] =
                        res.data.csrfToken;
                }
                return res.data.user;
            });
    };

    static login = (username: string, password: string) => {
        return getAxios()
            .post<{ token: string }>("/api/auth/login/", {
                username,
                password,
            })
            .then((res) => {
                axios.defaults.headers.common = {
                    Authorization: `Token ${res.data.token}`,
                    "Content-Type": "application/json",
                    "X-Csrftoken": getCookie("csrftoken") || "",
                };

                AuthService.setToken(`Token ${res.data.token}`);
                AuthService.getCurrentUser();

                message.success("Successvol ingelogd!");

                return res.data;
            })
            .catch(() => message.error("Foute gegevens"));
    };

    static logout = () => {
        return getAxios()
            .post("/api/auth/logout/")
            .then((res) => {
                AuthService.setToken("");
                return res.data;
            });
    };
}
