import * as React from "react";
import { Button, Input, InputRef } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useEffect } from "react";

export const FilteredDropdown = (props: FilterDropdownProps) => {
    const ref = React.useRef<InputRef>(null);

    useEffect(() => {
        if (props.visible && ref.current && ref.current.input) {
            setTimeout(() => {
                ref.current!.input!.focus();
            }, 100);
        }
    }, [props.visible, ref]);

    return (
        <div style={{ padding: "0.5em" }}>
            <Input
                value={props.selectedKeys[0]}
                onChange={(e) =>
                    props.setSelectedKeys(
                        e.target.value ? [e.target.value] : []
                    )
                }
                onPressEnter={() => props.confirm()}
                placeholder="Zoeken..."
                autoFocus={true}
                ref={ref}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.5em",
                }}
            >
                <Button
                    onClick={() => props.confirm()}
                    type="primary"
                    style={{ width: "45%" }}
                >
                    Zoeken
                </Button>
                <Button
                    onClick={() => {
                        props.setSelectedKeys([]);
                        props.confirm({
                            closeDropdown: true,
                        });
                    }}
                    style={{ width: "45%" }}
                >
                    Reset
                </Button>
            </div>
        </div>
    );
};
