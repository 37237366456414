import { getAxios } from "../../helpers/axiosClient";
import { IDebtor } from "./models/Debtor";
import { AxiosResponse } from "axios";
import { Moment } from "moment";
import { DateFormat } from "../../shared/constants";

export const licenseService = {
    listDebtors: (): Promise<AxiosResponse<IDebtor[]>> => {
        return getAxios().get(`/api/debtors/`);
    },

    modules: (
        debtorNumber: number
    ): Promise<
        AxiosResponse<{
            liveEta: boolean;
            optimizationMaxStops: number;
            optimizationMaxVehicles: number;
            routeOptimization: boolean;
        }>
    > => {
        return getAxios().get(`/api/debtors/${debtorNumber}/modules/`);
    },

    createLicenseInvoice: (debtorNumbers: number[], date: Moment) => {
        return getAxios().post(`/api/debtors/create_license_invoice/`, {
            debtorNumbers: debtorNumbers,
            date: date.format(DateFormat.DATE_TO_BACKEND),
        });
    },
};
