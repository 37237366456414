import * as React from "react";
import { DatePicker, Form, Modal } from "antd";
import { PropsWithChildren } from "react";
import moment from "moment";
import { licenseService } from "../service";
import axios from "axios";

interface IProps {
    debtorNumbers: number[];
    onClose: () => void;
}

const monthFormat = "YYYY/MM";

export const ModalCreateLicenseInvoice = (props: PropsWithChildren<IProps>) => {
    const [loading, setLoading] = React.useState(false);
    const [date, setDate] = React.useState(moment().startOf("month"));

    return (
        <Modal
            visible={true}
            title="Nieuwe licentiefactuur"
            onCancel={props.onClose}
            okText="Maak conceptfactuur"
            okButtonProps={{ loading }}
            onOk={async () => {
                setLoading(true);
                try {
                    await licenseService.createLicenseInvoice(
                        props.debtorNumbers,
                        date
                    );
                    props.onClose();
                    Modal.success({ title: "Facturen aangemaakt" });
                } catch (e) {
                    if (axios.isAxiosError(e) && e.response) {
                        Modal.warning({
                            width: "800px",
                            title: "Error",
                            content: (
                                <div>
                                    <span
                                        style={{
                                            whiteSpace: "pre",
                                            fontFamily: "monospace",
                                        }}
                                    >
                                        {JSON.stringify(
                                            e.response.data,
                                            undefined,
                                            4
                                        )}
                                    </span>
                                </div>
                            ),
                        });
                    } else {
                        Modal.error({ title: "Er is iets misgegaan." });
                    }
                } finally {
                    setLoading(false);
                }
            }}
        >
            <Form layout="vertical">
                <Form.Item label="Licentieperiode:">
                    <DatePicker
                        picker="month"
                        format={monthFormat}
                        size="large"
                        value={date}
                        allowClear={false}
                        onChange={(newDate) => {
                            if (newDate) {
                                setDate(newDate);
                            }
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
