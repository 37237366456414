import * as React from "react";
import { Layout } from "antd";
import { HourTable } from "../components/HourTable";

interface IProps {}

export const HourImplementationScreen: React.FC<IProps> = (props) => (
    <Layout>
        <HourTable
            defaultFilters={{
                billable: ["Yes"],
                status: ["In progress"],
                issueType: ["Project Management"],
            }}
        />
    </Layout>
);
