export interface IResponse<T> {
    results: T[];
    totalRecords: number;
    totalPages: number;
    currentPage: number;
}

export const defaultResponse: IResponse<any> = {
    results: [],
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
};
