import * as React from "react";
import { IUser } from "../modules/auth/models/User";
import { useState } from "react";

export interface IGlobalContext {
    user: IUser | null;
    setUser: (user: IUser | null) => void;
    userLoaded: boolean;
}

export const GlobalContext = React.createContext<IGlobalContext>({
    user: null,
    setUser: () => {},
    userLoaded: false,
});

export const GlobalContextProvider = (props: any) => {
    const [user, setUser] = useState<IUser | null | undefined>(undefined);

    return (
        <GlobalContext.Provider
            value={{
                user: user || null,
                setUser,
                userLoaded: user !== undefined,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};
