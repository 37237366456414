import * as React from "react";
import { HourService } from "../HourService";
import { Button, Input, Modal } from "antd";
import { IPdfTemplate } from "../models/PdfTemplate";
import { PdfTemplateSelect } from "./PdfTemplateSelect";
import { useEffect } from "react";

interface IProps {
    keys: string[];
    pdfTemplate?: IPdfTemplate | null;
}

export const DownloadPdfButton: React.FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [pdfTemplate, setPdfTemplate] = React.useState<IPdfTemplate | null>(
        props.pdfTemplate || null
    );

    useEffect(() => {
        setPdfTemplate(props.pdfTemplate || null);
    }, [props.pdfTemplate]);

    const openModal = () => {
        setIsOpen(true);
    };

    return (
        <>
            {isOpen && (
                <Modal
                    title={"Download PDF"}
                    visible={true}
                    onCancel={() => setIsOpen(false)}
                    okText="Download"
                    onOk={() =>
                        HourService.downloadPdf(props.keys, pdfTemplate?.id)
                    }
                >
                    <PdfTemplateSelect
                        value={pdfTemplate}
                        onChange={setPdfTemplate}
                        disabled={!!props.pdfTemplate}
                    />

                    <Input.TextArea
                        value={(pdfTemplate && pdfTemplate.description) || ""}
                        disabled={true}
                        rows={10}
                        style={{ marginTop: "1em" }}
                    />
                </Modal>
            )}
            <Button onClick={() => openModal()}>
                Download factuur bijlage
            </Button>
        </>
    );
};
