interface IProps {}

export const Toolbar = (props: React.PropsWithChildren<IProps>) => (
    <div
        className="toolbar shadow-lg"
        style={{
            height: "6em",
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            background: "white",
            zIndex: "100",
            display: "flex",
            justifyContent: "center",
            boxShadow: "0 1rem 3rem rgba(0, 0, 0, 0.175)",
        }}
    >
        <div
            style={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                padding: "0 2em",
                overflowY: "auto",
                maxWidth: "100%",
                flexDirection: "row",
            }}
        >
            {props.children}
        </div>
    </div>
);
