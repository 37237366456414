import * as React from "react";
import { useState } from "react";
import { Space, Table } from "antd";
import { LoadingButton } from "../../../shared/components/LoadingButton";
import { EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { useEffectOnce } from "../../../helpers/useEffectOnce";
import { OrganisationService } from "../OrganisationService";
import { defaultResponse, IResponse } from "../../../helpers/response";
import { IOrganisation } from "../models/Organisation";
import { useNavigate } from "react-router-dom";

interface IProps {}

export const OrganisationTable: React.FC<IProps> = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [organisations, setOrganisations] =
        useState<IResponse<IOrganisation>>(defaultResponse);
    const [filters, setFilters] = React.useState<{ [key: string]: string }>({});
    const navigate = useNavigate();

    const reload = (newFilters?: object) => {
        setLoading(true);
        OrganisationService.getOrganisations(newFilters || filters).then(
            (res) => {
                setOrganisations(res);
                setLoading(false);
            }
        );
    };

    useEffectOnce(() => {
        reload();
    });

    return (
        <>
            <Space
                style={{
                    backgroundColor: "lightgray",
                    display: "flex",
                    padding: "5px",
                }}
            >
                <LoadingButton
                    icon={<ReloadOutlined />}
                    onClick={() => reload()}
                    loading={loading}
                />
            </Space>
            <Table
                dataSource={organisations.results}
                pagination={{
                    pageSize: 50,
                    current: organisations.currentPage,
                    total: organisations.totalRecords,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                }}
                loading={loading}
                onChange={(pagination) => {
                    const newFilters = {
                        page: pagination.current!.toString(),
                    };

                    setFilters(newFilters);
                    reload(newFilters);
                }}
            >
                <Table.Column
                    key="id"
                    dataIndex="id"
                    title="#"
                    width="50px"
                    render={(id) => (
                        <EditOutlined
                            onClick={() => navigate(`/insights/${id}`)}
                        />
                    )}
                />
                <Table.Column key="name" dataIndex="name" title="Naam" />
            </Table>
        </>
    );
};
