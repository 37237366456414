import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginScreen } from "../modules/auth/screens/LoginScreen";
import { HourScreen } from "../modules/hours/screens/HourScreen";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";
import { HourImplementationScreen } from "../modules/hours/screens/HourImplementationScreen";
import { InsightScreen } from "../modules/organisations/screens/InsightScreen";
import { OrganisationInsightScreen } from "../modules/organisations/screens/OrganisationInsightScreen";
import { LicenseScreen } from "../modules/license/screens/LicenseScreen";
interface IProps {}

export const Router: React.FC<IProps> = (props) => {
    const globalContext = useContext(GlobalContext);
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen />} />

            {/*Authenticated routes*/}
            {globalContext.user && (
                <>
                    <Route path="/" element={<LicenseScreen />} />
                    <Route path="/hours" element={<HourScreen />} />
                    <Route
                        path="/implementation"
                        element={<HourImplementationScreen />}
                    />
                    <Route path="/insights" element={<InsightScreen />} />
                    <Route
                        path="/insights/:id"
                        element={<OrganisationInsightScreen />}
                    />
                </>
            )}
        </Routes>
    );
};
