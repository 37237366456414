import * as React from "react";
import { Layout } from "antd";
import { HourTable } from "../components/HourTable";

interface IProps {}

export const HourScreen: React.FC<IProps> = (props) => (
    <Layout.Content>
        <HourTable
            defaultFilters={{
                invoiceStatus: ["Not invoiced"],
                billable: ["Yes"],
                status: ["Closed"],
                issueType: ["New Feature"],
            }}
        />
    </Layout.Content>
);
