import { Layout } from "antd";
import * as React from "react";
import { OrganisationTable } from "../components/OrganisationTable";

interface IProps {}

export const InsightScreen: React.FC<IProps> = (props) => (
    <Layout>
        <OrganisationTable />
    </Layout>
);
