import * as React from "react";
import { Statistic } from "antd";

interface IProps {
    price: number;
}

export const Price: React.FC<IProps> = (props) => {
    const rounded = Math.round((props.price + Number.EPSILON) * 100) / 100;

    const getColor = (): string | undefined => {
        if (rounded < 0) {
            return "red";
        } else if (rounded > 0) {
            return "green";
        }

        return undefined;
    };

    return (
        <Statistic
            value={props.price}
            precision={2}
            valueStyle={{
                color: getColor(),
                fontSize: "14px",
            }}
            formatter={(value) =>
                new Intl.NumberFormat("nl-NL", {
                    style: "currency",
                    currency: "EUR",
                }).format(Number(value))
            }
        />
    );
};
