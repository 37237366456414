import { getAxios } from "../../helpers/axiosClient";
import { IOrganisation } from "./models/Organisation";
import { IResponse } from "../../helpers/response";
import * as qs from "qs";
import { IOrganisationInsights } from "./models/OrganisationInsights";
import { Moment } from "moment";

export class OrganisationService {
    static getOrganisations = (
        filters: object
    ): Promise<IResponse<IOrganisation>> =>
        getAxios()
            .get(
                `/api/organisations/${qs.stringify(filters, {
                    addQueryPrefix: true,
                    arrayFormat: "repeat",
                })}`
            )
            .then((res) => res.data);

    static getOrganisation = (id: number): Promise<IOrganisation> =>
        getAxios()
            .get(`/api/organisations/${id}/`)
            .then((res) => res.data);

    static getOrganisationInsights = (
        id: number,
        start: Moment,
        end: Moment
    ): Promise<IOrganisationInsights> =>
        getAxios()
            .get(
                `/api/organisations/${id}/insights/${qs.stringify(
                    {
                        start: start.format("YYYY-MM-DD"),
                        end: end.format("YYYY-MM-DD"),
                    },
                    {
                        addQueryPrefix: true,
                        arrayFormat: "repeat",
                    }
                )}`
            )
            .then((res) => res.data);
}
