import * as React from "react";
import { PropsWithChildren } from "react";
import { Modal, Table } from "antd";
import { useEffectOnce } from "../../../helpers/useEffectOnce";
import { licenseService } from "../service";

interface IProps {
    debtorNumber: number;
    onClose: () => void;
}

export const ModalDebtorInfo = (props: PropsWithChildren<IProps>) => {
    const [data, setData] = React.useState<{
        liveEta: boolean;
        optimizationMaxStops: number;
        optimizationMaxVehicles: number;
        routeOptimization: boolean;
    } | null>(null);

    useEffectOnce(() => {
        licenseService.modules(props.debtorNumber).then((res) => {
            if (res.status === 200) {
                setData(res.data);
            }
        });
    });

    return (
        <Modal
            visible={true}
            title="Informatie"
            onCancel={props.onClose}
            okText="OK"
            onOk={props.onClose}
            cancelButtonProps={{ style: { display: "none" } }}
        >
            {data && (
                <Table
                    pagination={false}
                    size="small"
                    rowKey="label"
                    dataSource={[
                        {
                            label: "Route optimalisatie",
                            value: String(data.routeOptimization),
                        },
                        { label: "Live ETA", value: String(data.liveEta) },
                        {
                            label: "Max stops optimalisatie",
                            value: data.optimizationMaxStops,
                        },
                        {
                            label: "Max voertuigen optimalisatie",
                            value: data.optimizationMaxVehicles,
                        },
                    ]}
                    columns={[
                        {
                            title: "Module",
                            key: "1",
                            dataIndex: "label",
                        },
                        {
                            title: "Waarde",
                            key: "2",
                            dataIndex: "value",
                        },
                    ]}
                />
            )}
        </Modal>
    );
};
