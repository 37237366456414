import * as React from "react";
import { Button, ButtonProps, Modal } from "antd";
import { useState } from "react";

interface IProps {
    confirm?: boolean;
}

const isPromise = (p: any): boolean => {
    if (typeof p === "object" && typeof p.then === "function") {
        return true;
    }

    return false;
};

export const LoadingButton: React.FC<IProps & ButtonProps> = ({
    confirm,
    ...props
}) => {
    const [loading, setLoading] = useState(false);

    const onClickOverride = (e: React.MouseEvent<HTMLElement>) => {
        setLoading(true);
        const result = props.onClick!(e);

        if (isPromise(result)) {
            result
                // @ts-ignore
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        } else {
            setLoading(false);
        }
    };

    return (
        <Button
            {...props}
            loading={props.loading || loading}
            onClick={(e) => {
                if (!props.onClick) {
                    return;
                }

                if (confirm) {
                    Modal.confirm({
                        title: "Weet u het zeker?",
                        okText: "Ja",
                        cancelText: "Nee",
                        onOk: () => onClickOverride(e),
                    });
                } else {
                    onClickOverride(e);
                }
            }}
        />
    );
};
