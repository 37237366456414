import { Select } from "antd";
import React, { useEffect } from "react";
import { IPdfTemplate } from "../models/PdfTemplate";
import { HourService } from "../HourService";

interface IProps {
    value: IPdfTemplate | null;
    onChange: (value: IPdfTemplate | null) => void;
    disabled?: boolean;
}

export const PdfTemplateSelect: React.FC<IProps> = (props) => {
    const [items, setItems] = React.useState<IPdfTemplate[]>([]);

    useEffect(() => {
        HourService.getPdfTemplates().then((templates) => setItems(templates));
    }, []);

    return (
        <Select
            options={[
                {
                    value: null,
                    label: "Standaard",
                },
                ...items.map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            ]}
            onChange={(newValue) =>
                props.onChange(
                    items.find((item) => item.id === newValue) || null
                )
            }
            value={props.value?.id || null}
            style={{ width: "100%" }}
            disabled={props.disabled}
        />
    );
};
