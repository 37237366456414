import * as React from "react";
import { useState } from "react";
import { Button, Popover, Space, Table } from "antd";
import { HourService } from "../HourService";
import { getAmountToInvoice, IHour } from "../models/Hour";
import { InvoiceTicketsModal } from "./InvoiceTicketsModal";
import { useEffectOnce } from "../../../helpers/useEffectOnce";
import { FilteredDropdown } from "../../../helpers/FilteredDropdown";
import { dicToSnake } from "../../../helpers/dictHelpers";
import { defaultResponse, IResponse } from "../../../helpers/response";
import { HourSummary } from "./HourSummary";
import { ClearOutlined, ReloadOutlined } from "@ant-design/icons";
import { LoadingButton } from "../../../shared/components/LoadingButton";
import { DownloadPdfButton } from "./DownloadPdfButton";

interface IProps {
    defaultFilters: { [key: string]: string[] };
}

export const HourTable: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [hours, setHours] = useState<IResponse<IHour>>(defaultResponse);
    const [invoiceTickets, setInvoiceTickets] = useState<IHour[]>([]);
    const [selectedHours, setSelectedHours] = useState<IHour[]>([]);

    const [filters, setFilters] = useState<{ [key: string]: any }>(
        props.defaultFilters
    );

    const changeFilterValue = (key: string, value: string) => {
        const newFilters = {
            ...filters,
            [key]: [value],
        };

        setFilters(newFilters);
        getHours(newFilters);
    };

    const getHours = (customFilters?: object) => {
        setLoading(true);
        return HourService.getHours(
            dicToSnake({
                ...filters,
                ...customFilters,
            })
        )
            .then((newHours) => {
                setHours(newHours);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    useEffectOnce(() => {
        getHours();
    });

    return (
        <>
            {invoiceTickets.length > 0 && (
                <InvoiceTicketsModal
                    hours={invoiceTickets}
                    onClose={(changed) => {
                        setInvoiceTickets([]);
                        if (changed) {
                            getHours();
                        }
                    }}
                />
            )}
            <Space
                style={{
                    backgroundColor: "lightgray",
                    display: "flex",
                    padding: "5px",
                }}
            >
                <LoadingButton
                    icon={<ReloadOutlined />}
                    onClick={() => getHours()}
                    loading={loading}
                />
                <LoadingButton
                    icon={<ClearOutlined />}
                    onClick={() => setFilters({})}
                />
                <Button
                    disabled={selectedHours.length === 0}
                    type="primary"
                    onClick={() =>
                        setInvoiceTickets(
                            selectedHours.sort((a, b) =>
                                a.key.localeCompare(b.key)
                            )
                        )
                    }
                >
                    Factureren ({selectedHours.length})
                </Button>
            </Space>
            <Table
                dataSource={hours.results}
                pagination={{
                    pageSize: 10,
                    current: hours.currentPage,
                    total: hours.totalRecords,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                }}
                rowSelection={{
                    selectedRowKeys: selectedHours.map((hour) => hour.key),
                    onChange: (selectedRowKeys) =>
                        setSelectedHours(
                            hours.results.filter((hour) =>
                                selectedRowKeys.includes(hour.key)
                            )
                        ),
                }}
                loading={loading}
                onChange={(pagination, filters, sorter, extra) => {
                    const newFilters = {
                        ...filters,
                        page: pagination.current,
                    };

                    setFilters(newFilters);
                    getHours(newFilters);
                }}
            >
                <Table.Column
                    key="key"
                    dataIndex="key"
                    title="Ticket"
                    filteredValue={filters["key"] || []}
                    filterDropdown={(props) => <FilteredDropdown {...props} />}
                />
                <Table.Column
                    key="billable"
                    dataIndex="billable"
                    title="Factureerbaar"
                    filteredValue={filters["billable"] || []}
                    filterMultiple={false}
                    filters={[
                        {
                            text: "Ja",
                            value: "Yes",
                        },
                        {
                            text: "Nee",
                            value: "No",
                        },
                    ]}
                />
                <Table.Column
                    key="issueType"
                    dataIndex="issueType"
                    title="Type"
                    filteredValue={filters["issueType"] || []}
                    filterDropdown={(props) => <FilteredDropdown {...props} />}
                />
                <Table.Column
                    key="status"
                    dataIndex="status"
                    title="Status"
                    filterMultiple={false}
                    filteredValue={filters["status"] || []}
                    filters={[
                        {
                            text: "Open",
                            value: "Open",
                        },
                        {
                            text: "In progress",
                            value: "In progress",
                        },
                        {
                            text: "Closed",
                            value: "Closed",
                        },
                    ]}
                />
                <Table.Column<IHour>
                    key="summary"
                    dataIndex="summary"
                    title="Samenvatting"
                    filteredValue={filters["summary"] || []}
                    render={(summary, record) => (
                        <span
                            onDoubleClick={() =>
                                window.open(`/api/hours/${record.key}/`)
                            }
                        >
                            {summary}
                        </span>
                    )}
                />
                <Table.Column
                    key="organisation"
                    dataIndex="organisation"
                    title="Organisatie"
                    filteredValue={filters["organisation"] || []}
                    filterDropdown={(props) => <FilteredDropdown {...props} />}
                    render={(organisation) => (
                        <Button
                            type="link"
                            onClick={() =>
                                changeFilterValue(
                                    "organisation",
                                    organisation.name
                                )
                            }
                        >
                            {organisation.name}
                        </Button>
                    )}
                />
                <Table.Column<IHour>
                    key="totalEstimateHours"
                    dataIndex="totalEstimateHours"
                    title="Ingeschatte uren"
                    filteredValue={filters["totalEstimateHours"] || []}
                    render={(value, record) => (
                        <Popover content={<HourSummary hour={record} />}>
                            {value} uren
                        </Popover>
                    )}
                />
                <Table.Column<IHour>
                    key="totalSpendHours"
                    dataIndex="totalSpendHours"
                    title="Gewerkte uren"
                    filteredValue={filters["totalSpendHours"] || []}
                    render={(value, record) => (
                        <Popover content={<HourSummary hour={record} />}>
                            {value} uren
                        </Popover>
                    )}
                />
                <Table.Column<IHour>
                    key="toInvoiceHours"
                    dataIndex="toInvoiceHours"
                    title="Te factureren"
                    filteredValue={filters["toInvoiceHours"] || []}
                    render={(_, record) => (
                        <Popover content={<HourSummary hour={record} />}>
                            {getAmountToInvoice(record)} uren
                        </Popover>
                    )}
                />
                <Table.Column
                    key="invoiceStatus"
                    dataIndex="invoiceStatus"
                    title="Factuur status"
                    filteredValue={filters["invoiceStatus"] || []}
                    filterDropdown={(props) => <FilteredDropdown {...props} />}
                />
                <Table.Column
                    key="pdf"
                    dataIndex="key"
                    filteredValue={filters["pdf"] || []}
                    title=""
                    render={(key) => <DownloadPdfButton keys={[key]} />}
                />
                <Table.Column<IHour>
                    key="invoice"
                    dataIndex="key"
                    filteredValue={filters["invoice"] || []}
                    title=""
                    render={(key, record) => (
                        <Button
                            type="default"
                            onClick={() => setInvoiceTickets([record])}
                        >
                            Factureren
                        </Button>
                    )}
                />
            </Table>
        </>
    );
};
