export const toSnake = (s: string) => {
    return s
        .replace(/([a-z\d])([A-Z])/g, "$1_$2")
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1_$2")
        .toLowerCase();
};

export const dicToSnake = (dict: {
    [index: string]: any;
}): { [index: string]: any } => {
    const obj: { [key: string]: any } = {};
    Object.keys(dict).forEach((key: string) => {
        if (dict[key] !== undefined) {
            obj[toSnake(key)] = dict[key];
        }
    });

    return obj;
};
