import * as React from "react";
import { Layout, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "../../../helpers/useEffectOnce";
import { OrganisationService } from "../OrganisationService";
import { OrganisationDetails } from "../components/OrganisationDetails";
import { IOrganisationInsights } from "../models/OrganisationInsights";
import moment, { Moment } from "moment";

interface IProps {}

export const OrganisationInsightScreen: React.FC<IProps> = (props) => {
    const { id } = useParams();
    const [organisationInsights, setOrganisationInsights] =
        React.useState<IOrganisationInsights | null>(null);
    const [range, setRange] = React.useState<[Moment, Moment]>([
        moment().startOf("month"),
        moment().endOf("month"),
    ]);
    const [loading, setLoading] = React.useState(false);

    const reload = () => {
        if (id) {
            setLoading(true);
            OrganisationService.getOrganisationInsights(
                Number(id),
                range[0],
                range[1]
            )
                .then((res) => {
                    setOrganisationInsights(res);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }
    };

    useEffectOnce(() => {
        reload();
    });

    return (
        <Layout>
            {loading && <Spin />}

            {!loading && !organisationInsights && (
                <p>Er is iets fout gegaan bij het ophalen</p>
            )}

            {!loading && organisationInsights && (
                <OrganisationDetails
                    organisationInsights={organisationInsights}
                    onReload={() => reload()}
                    range={range}
                    onRangeChange={(start, end) => {
                        setRange([start, end]);
                    }}
                />
            )}
        </Layout>
    );
};
