import * as React from "react";
import { Button, Card, Select, Statistic, Table, Tabs, Typography } from "antd";
import { IOrganisationInsights } from "../models/OrganisationInsights";
import { Price } from "./Price";
import { HourTable } from "../../hours/components/HourTable";
import { HourChart, PeriodOption } from "../../hours/components/HourChart";
import { Moment } from "moment";
import { ArrowRightOutlined } from "@ant-design/icons";
import { CustomDatePicker } from "../../../shared/components/CustomDatePicker";

interface IProps {
    organisationInsights: IOrganisationInsights;
    range: [Moment, Moment];
    onRangeChange: (start: Moment, end: Moment) => void;
    onReload: () => void;
}

interface ITotal {
    hours: number;
    totalPurchase: number;
}

interface ITableRow {
    key: string;

    ncsTotals: ITotal;
    tmsTotals: ITotal;

    invoicedAmount: number;
    totalSellPrice: number;
    totalPurchasePrice: number;
    revenue: number;
}

export const OrganisationDetails: React.FC<IProps> = (props) => {
    const [period, setPeriod] = React.useState<PeriodOption>("week");

    const tableRows: ITableRow[] = [];

    Object.entries(props.organisationInsights.hoursPerIssueType).forEach(
        ([key, value]) => {
            const tmsTotalPurchase =
                value.tmsSpendHours * value.tmsPurchasePricePerHour * -1;

            const ncsTotalPurchase =
                value.ncsSpendHours * value.ncsPurchasePricePerHour * -1;

            const totalPurchase = tmsTotalPurchase + ncsTotalPurchase;
            const totalSell = value.invoicedAmount * value.ncsSellPricePerHour;

            tableRows.push({
                key,
                invoicedAmount: value.invoicedAmount,
                tmsTotals: {
                    hours: value.tmsSpendHours,
                    totalPurchase: tmsTotalPurchase,
                },
                ncsTotals: {
                    hours: value.ncsSpendHours,
                    totalPurchase: ncsTotalPurchase,
                },
                totalSellPrice: totalSell,
                totalPurchasePrice: totalPurchase,
                revenue: totalPurchase + totalSell,
            });
        }
    );

    const renderTotal = (hours: number, totalPrice: number) => (
        <div style={{ display: "flex" }}>
            {renderHours(hours)}
            <div style={{ marginLeft: "1em", display: "flex" }}>
                (<Price price={totalPrice} />)
            </div>
        </div>
    );

    const renderHours = (hours: number) => (
        <Statistic
            value={hours}
            precision={2}
            valueStyle={{
                fontSize: "14px",
            }}
        />
    );

    return (
        <div>
            <Typography.Title style={{ marginTop: "1em" }}>
                {props.organisationInsights.organisation.name}
            </Typography.Title>

            <Tabs defaultActiveKey="summary" centered={true}>
                <Tabs.TabPane key="summary" tab="Samenvatting">
                    <Card>
                        <div
                            style={{
                                textAlign: "center",
                                marginBottom: "1em",
                                fontStyle: "italic",
                            }}
                        >
                            {props.range[0].format("DD-MM-YYYY")}
                            {" - "}
                            {props.range[1].format("DD-MM-YYYY")}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CustomDatePicker
                                value={props.range}
                                onChange={(start, end) =>
                                    props.onRangeChange(start, end)
                                }
                                style={{
                                    marginBottom: "1em",
                                    marginRight: "1em",
                                }}
                            />
                            <Button
                                icon={<ArrowRightOutlined />}
                                onClick={() => props.onReload()}
                            />
                        </div>

                        <Table<ITableRow>
                            dataSource={tableRows}
                            pagination={false}
                            footer={(records) => (
                                <b>
                                    Totale omzet:{" "}
                                    <Price
                                        price={records.reduce(
                                            (prev, cur) => prev + cur.revenue,
                                            0
                                        )}
                                    />
                                </b>
                            )}
                        >
                            <Table.Column
                                key="key"
                                dataIndex="key"
                                title="Naam"
                                fixed={"left"}
                            />
                            <Table.Column
                                key="tmsTotals"
                                dataIndex="tmsTotals"
                                title="Tijd TMS"
                                render={(total: ITotal) =>
                                    renderTotal(
                                        total.hours,
                                        total.totalPurchase
                                    )
                                }
                            />
                            <Table.Column
                                key="ncsTotals"
                                dataIndex="ncsTotals"
                                title="Tijd NCS"
                                render={(total: ITotal) =>
                                    renderTotal(
                                        total.hours,
                                        total.totalPurchase
                                    )
                                }
                            />

                            <Table.Column
                                key="totalHours"
                                dataIndex="totalHours"
                                title="Totaal gewerkt"
                                render={(_, record: ITableRow) =>
                                    renderTotal(
                                        record.tmsTotals.hours +
                                            record.ncsTotals.hours,
                                        record.ncsTotals.totalPurchase +
                                            record.tmsTotals.totalPurchase
                                    )
                                }
                            />

                            <Table.Column<ITableRow>
                                key="invoicedAmount"
                                dataIndex="invoicedAmount"
                                title="Facturabel"
                                render={(value: number, record) =>
                                    renderTotal(value, record.totalSellPrice)
                                }
                            />

                            <Table.Column
                                key="revenue"
                                dataIndex="revenue"
                                title="Omzet"
                                render={(price) => <Price price={price} />}
                            />
                        </Table>
                    </Card>
                </Tabs.TabPane>
                <Tabs.TabPane key="charter" tab="Grafiek">
                    <Card>
                        <Select<PeriodOption>
                            value={period}
                            onChange={(newValue) => setPeriod(newValue)}
                            style={{
                                marginTop: " 1em",
                                marginBottom: "1em",
                            }}
                            options={[
                                {
                                    value: "day",
                                    label: "Dag",
                                },
                                {
                                    value: "week",
                                    label: "Week",
                                },
                                {
                                    value: "month",
                                    label: "Maand",
                                },
                            ]}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <HourChart
                                periodOption={period}
                                hoursPerType={Object.entries(
                                    props.organisationInsights.hoursPerIssueType
                                ).reduce(
                                    (prev, [key, value]) => ({
                                        ...prev,
                                        [key]: value.tickets,
                                    }),
                                    {}
                                )}
                            />
                        </div>
                    </Card>
                </Tabs.TabPane>
                <Tabs.TabPane key="tickets" tab="Tickets">
                    <HourTable
                        defaultFilters={{
                            organisation: [
                                props.organisationInsights.organisation.name,
                            ],
                        }}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
