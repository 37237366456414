import * as React from "react";
import { useState } from "react";
import { IHour } from "../models/Hour";
import { Button, Form, InputNumber, message, Modal } from "antd";
import { LoadingButton } from "../../../shared/components/LoadingButton";
import { HourService } from "../HourService";
import { IPdfTemplate } from "../models/PdfTemplate";
import { useEffectOnce } from "../../../helpers/useEffectOnce";
import { BaseForm } from "../../../shared/components/BaseForm";
import { Moment } from "moment";

interface IProps {
    pdfTemplate: IPdfTemplate | null;
    startDate: Moment | null;
    endDate: Moment | null;

    hours: IHour[];

    onDone: (data: {
        hours: number;
        invoiceNumber: string;
        identifier: string;
    }) => void;
    onClose: () => void;
}

export const ExportInvoiceModal: React.FC<IProps> = (props) => {
    const [unitPriceExcl, setUnitPriceExcl] = useState(0);

    useEffectOnce(() => {
        if (props.hours.length > 0) {
            HourService.getDefaultRate(props.hours[0]).then((rate) =>
                setUnitPriceExcl(rate)
            );
        }
    });

    return (
        <Modal
            title="Exporteren naar WeFact"
            visible={true}
            onCancel={() => props.onClose()}
            footer={
                <div>
                    <Button onClick={() => props.onClose()}>Sluiten</Button>

                    {/* Default export, no hour summary */}
                    <LoadingButton
                        type="primary"
                        onClick={async () => {
                            try {
                                const res = await HourService.export({
                                    pdfTemplateId:
                                        props.pdfTemplate?.id || null,
                                    startDate: props.startDate,
                                    endDate: props.endDate,
                                    unitPriceExcl,
                                    tickets: props.hours.map(
                                        (hour) => hour.key
                                    ),
                                });

                                props.onDone({
                                    hours: res.data.hours,
                                    invoiceNumber: res.data.invoice.invoiceCode,
                                    identifier: res.data.invoice.identifier,
                                });
                            } catch (e) {
                                console.error(e);
                                message.error(
                                    JSON.stringify((e as any).response.data)
                                );
                            }
                        }}
                        confirm={true}
                    >
                        Exporteren
                    </LoadingButton>
                </div>
            }
        >
            <BaseForm>
                <Form.Item label="Uur tarief excl.">
                    <InputNumber
                        value={unitPriceExcl}
                        onChange={setUnitPriceExcl}
                        addonBefore="€"
                        step={0.01}
                    />
                </Form.Item>
            </BaseForm>
        </Modal>
    );
};
