import moment, { Moment } from "moment";
import * as React from "react";
import { CartesianGrid, BarChart, Tooltip, XAxis, YAxis, Bar } from "recharts";
import { ISimpleHour } from "../models/SimpleHour";

export type PeriodOption = "month" | "week" | "day";

// eslint-disable-next-line no-unused-vars
const periodOptions: { [key in PeriodOption]: (m: Moment) => string } = {
    month: (m) => m.month().toString(),
    week: (m) => m.isoWeek().toString(),
    day: (m) => m.format("YYYY-MM-DD"),
};

interface IProps {
    hoursPerType: { [key: string]: ISimpleHour[] };
    periodOption: PeriodOption;
}

export const HourChart: React.FC<IProps> = (props) => {
    const hoursInPeriodPerType: { [key: string]: { [key: string]: number } } =
        {};

    Object.entries(props.hoursPerType).forEach(([issueType, hours]) => {
        hours.forEach((hour) => {
            const date = hour.firstWorkLogDate;
            if (!date || !hour.totalSpendHours) {
                return;
            }

            const period = periodOptions[props.periodOption](
                moment(date, "YYYY-MM-DD")
            );

            if (!hoursInPeriodPerType[period]) {
                hoursInPeriodPerType[period] = {};
            }
            if (!hoursInPeriodPerType[period][issueType]) {
                hoursInPeriodPerType[period][issueType] = 0;
            }
            hoursInPeriodPerType[period][issueType] += hour.totalSpendHours;
        });
    });

    const getColorForIssueType = (issueType: string) => {
        if (issueType === "newFeature") {
            return "blue";
        } else if (issueType === "projectManagement") {
            return "green";
        }

        return "red";
    };

    // Format the data into an array.
    const data = Object.entries(hoursInPeriodPerType)
        .map(([date, hours]) => ({
            date,
            ...hours,
        }))
        .sort((a, b) => a.date.localeCompare(b.date));

    return (
        <div>
            <BarChart width={1000} height={400} data={data}>
                <CartesianGrid />
                <Tooltip formatter={(value: any) => `${value} uren`} />
                <XAxis dataKey="date" />
                <YAxis />
                {Object.keys(props.hoursPerType).map((issueType) => (
                    <Bar
                        type="monotone"
                        dataKey={issueType}
                        fill={getColorForIssueType(issueType)}
                    />
                ))}
            </BarChart>
        </div>
    );
};
