import * as React from "react";
import "./App.css";
import { Router } from "./router/Router";
import { AuthGuard } from "./modules/auth/components/AuthGuard";
import { AuthenticatedMenu } from "./menu/AuthenticatedMenu";
import { GlobalContextProvider } from "./context/GlobalContextProvider";
import { ConfigProvider, Layout } from "antd";
import moment from "moment";

import nlNL from "antd/es/locale/nl_NL";
import "moment/locale/nl";

import "antd/dist/antd.min.css";

moment.locale("nl", {
    week: {
        dow: 1, // Monday is the first day of the week
    },
});

function App() {
    return (
        <div className="App">
            <GlobalContextProvider>
                <ConfigProvider locale={nlNL}>
                    <Layout style={{ height: "100%" }}>
                        <AuthGuard>
                            <AuthenticatedMenu />
                        </AuthGuard>

                        <Router />
                    </Layout>
                </ConfigProvider>
            </GlobalContextProvider>
        </div>
    );
}

export default App;
