import * as React from "react";
import { useState } from "react";
import {
    Alert,
    Button,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Space,
    Typography,
} from "antd";
import { HourService } from "../HourService";
import { getAmountToInvoice, IHour } from "../models/Hour";
import { HourSummary } from "./HourSummary";
import { LoadingButton } from "../../../shared/components/LoadingButton";
import { ExportInvoiceModal } from "./ExportInvoiceModal";
import { DownloadPdfButton } from "./DownloadPdfButton";
import { PdfTemplateSelect } from "./PdfTemplateSelect";
import { IPdfTemplate } from "../models/PdfTemplate";
import { BaseForm } from "../../../shared/components/BaseForm";
import { Moment } from "moment/moment";

interface IProps {
    hours: IHour[];
    onClose: (changed: boolean) => void;
}

export const InvoiceTicketsModal: React.FC<IProps> = (props) => {
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [pdfTemplate, setPdfTemplate] = React.useState<IPdfTemplate | null>(
        null
    );

    // Keep track of the invoice that was created.
    const [exportedInvoice, setExportedInvoice] = useState<{
        hours: number;
        identifier: string;
        invoiceNumber: string;
        isSent: boolean;
    } | null>(null);

    // State used for updating the information in Jira.
    const [invoiceNr, setInvoiceNr] = useState("");
    const [hours, setHours] = useState(0);

    const [exportModal, setExportModal] = useState(false);

    const keys = props.hours.map((hour) => hour.key);

    const updateHour = () => {
        return HourService.invoice(
            props.hours.map((hour) => ({
                ticket: hour.key,
                amount:
                    !!startDate && !!endDate ? hours : getAmountToInvoice(hour),
                unitPriceExcl: 0,
                pdfTemplateId: pdfTemplate?.id || null,
            })),
            invoiceNr
        ).then(() => props.onClose(true));
    };

    return (
        <>
            {exportModal && (
                <ExportInvoiceModal
                    pdfTemplate={pdfTemplate}
                    startDate={startDate}
                    endDate={endDate}
                    hours={props.hours}
                    onClose={() => setExportModal(false)}
                    onDone={(data) => {
                        setExportedInvoice({
                            hours,
                            invoiceNumber: data.invoiceNumber,
                            identifier: data.identifier,
                            isSent: false,
                        });
                        setHours(hours);
                        setExportModal(false);
                    }}
                />
            )}
            <Modal
                width="1100px"
                visible={true}
                onCancel={() => props.onClose(false)}
                title={keys.join(", ")}
                footer={
                    <div>
                        <Button onClick={() => props.onClose(false)}>
                            Sluiten
                        </Button>
                    </div>
                }
            >
                {props.hours.map((hour) => (
                    <div key={hour.key}>
                        <HourSummary hour={hour} />
                        <Divider />
                    </div>
                ))}

                <Typography.Title level={4}>
                    Stap #1: Maak WeFact factuur
                </Typography.Title>

                <BaseForm>
                    <Form.Item label="Template">
                        <PdfTemplateSelect
                            value={pdfTemplate}
                            onChange={setPdfTemplate}
                        />
                    </Form.Item>

                    <Form.Item label="Start datum">
                        <DatePicker
                            value={startDate}
                            onChange={setStartDate}
                            disabled={props.hours.length !== 1}
                        />
                    </Form.Item>

                    <Form.Item label="Eind datum">
                        <DatePicker
                            value={endDate}
                            onChange={setEndDate}
                            disabled={props.hours.length !== 1}
                        />
                    </Form.Item>
                </BaseForm>

                <Space>
                    <Button
                        type="primary"
                        disabled={!!exportedInvoice}
                        onClick={() => setExportModal(true)}
                    >
                        Exporteer naar WeFact
                    </Button>

                    <DownloadPdfButton keys={keys} pdfTemplate={pdfTemplate} />

                    <Button
                        disabled={!startDate || !endDate}
                        onClick={() => {
                            if (startDate && endDate) {
                                HourService.downloadHourLogs(
                                    props.hours[0].key,
                                    startDate,
                                    endDate
                                );
                            } else {
                                message.error(
                                    "Start en einddatum moeten zijn ingevuld."
                                );
                            }
                        }}
                    >
                        Download uren log
                    </Button>
                </Space>

                {exportedInvoice && (
                    <>
                        <Divider />
                        <Alert
                            description={
                                <>
                                    Factuurnummer in WeFact:{" "}
                                    <a
                                        href={`https://www.mijnwefact.nl/facturen/bekijken/${exportedInvoice.identifier}`}
                                        target="_blank"
                                    >
                                        {exportedInvoice.invoiceNumber}
                                    </a>
                                </>
                            }
                            action={
                                <LoadingButton
                                    confirm={true}
                                    disabled={exportedInvoice.isSent}
                                    onClick={() =>
                                        HourService.sendInvoice(
                                            exportedInvoice.invoiceNumber
                                        ).then((res) => {
                                            setExportedInvoice({
                                                ...exportedInvoice,
                                                isSent: true,
                                            });
                                            setInvoiceNr(
                                                res.data.invoice.invoiceCode
                                            );
                                            return res;
                                        })
                                    }
                                >
                                    Verstuur factuur
                                </LoadingButton>
                            }
                        />
                    </>
                )}

                <Divider />

                <Typography.Title level={4}>
                    Stap #2: Update informatie in Jira
                </Typography.Title>

                <BaseForm>
                    <Form.Item label="Factuurnummer">
                        <Input
                            placeholder={"factuurnummer"}
                            value={invoiceNr}
                            onChange={(e) => setInvoiceNr(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Gefactureerde uren">
                        <InputNumber
                            placeholder={"uren"}
                            value={hours}
                            onChange={(e) => setHours(e)}
                        />
                    </Form.Item>
                </BaseForm>

                <LoadingButton
                    type="primary"
                    onClick={() => updateHour()}
                    confirm={true}
                >
                    Update in Jira
                </LoadingButton>
            </Modal>
        </>
    );
};
