import * as React from "react";
import { Form, FormProps } from "antd";

interface IProps {}

export const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

interface IProps {
    children: React.ReactNode;
}

export const BaseForm: React.FC<IProps & FormProps> = ({
    children,
    ...props
}) => (
    <Form layout="horizontal" labelAlign="left" {...formItemLayout} {...props}>
        {children}
    </Form>
);
