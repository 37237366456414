import * as React from "react";
import { DatePicker, Radio } from "antd";
import moment, { Moment } from "moment";
import { useState } from "react";

interface IProps {
    value: [Moment, Moment];
    onChange: (start: Moment, end: Moment) => void;
    style?: React.CSSProperties;
}

type PickerType = "week" | "month" | "quarter" | "year";

export const CustomDatePicker: React.FC<IProps> = (props) => {
    const getDefaultPickerType = (): PickerType => {
        const days = props.value[1].diff(props.value[0], "days");

        if (days <= 7) {
            return "week";
        } else if (days <= 30) {
            return "month";
        } else if (days <= 93) {
            return "quarter";
        } else {
            return "year";
        }
    };

    const [pickerType, setPickerType] = useState<PickerType>(
        getDefaultPickerType()
    );

    const changeValue = (picker: PickerType, date: Moment) => {
        setPickerType(picker);
        props.onChange(
            date.clone().startOf(picker),
            date.clone().endOf(picker)
        );
    };

    return (
        <div
            style={{
                display: "flex",
                ...props.style,
            }}
        >
            <DatePicker
                picker={pickerType}
                value={props.value[0]}
                onChange={(newDate) => {
                    if (newDate) {
                        changeValue(pickerType, newDate);
                    }
                }}
                allowClear={false}
                style={{
                    marginRight: "1em",
                }}
            />

            <Radio.Group
                value={pickerType}
                onChange={(e) => {
                    changeValue(e.target.value, moment());
                }}
            >
                <Radio.Button value="week">Week</Radio.Button>
                <Radio.Button value="month">Maand</Radio.Button>
                <Radio.Button value="quarter">Kwartiel</Radio.Button>
                <Radio.Button value="year">Jaar</Radio.Button>
            </Radio.Group>
        </div>
    );
};
