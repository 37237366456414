import { round } from "../../../shared/functions";

type InvoiceStatus = "Invoiced" | "Not invoiced" | "Attention";

export interface IHour {
    key: string;
    status: string;
    summary: string;
    organisation: {
        name: string;
        debitNumber: string;
    };

    issueType: string;
    billable: "Yes" | "No";

    invoiceNrs: string[];
    invoicedAmount: number;
    invoiceStatus: InvoiceStatus;

    totalEstimateHours: number;
    totalSpendHours: number;
    totalRemainingHours: number;
}

export const getAmountToInvoice = (hour: IHour) =>
    round(hour.totalSpendHours - hour.invoicedAmount, 2);
