import * as React from "react";
import { Menu } from "antd";
import { AuthService } from "../modules/auth/authService";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";
import { useNavigate, useLocation } from "react-router-dom";

interface IProps {}

export const AuthenticatedMenu: React.FC<IProps> = (props) => {
    const globalContext = useContext(GlobalContext);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Menu
            mode="horizontal"
            theme="light"
            selectedKeys={[location.pathname]}
            items={[
                {
                    key: "/",
                    label: "Home",
                    onClick: () => navigate(`/`),
                },
                {
                    key: "/hours",
                    label: "Development",
                    onClick: () => navigate(`/hours`),
                },
                {
                    key: "/implementation",
                    label: "Implementatie",
                    onClick: () => navigate(`/implementation`),
                },
                {
                    key: "/insights",
                    label: "Inzage",
                    onClick: () => navigate("/insights"),
                },
                {
                    key: "toAdmin",
                    label: "Ga naar admin",
                    onClick: () => window.location.assign("/admin"),
                },
                {
                    key: "user",
                    label: globalContext.user?.username,
                },
                {
                    key: "logout",
                    label: "Uitloggen",
                    onClick: () =>
                        AuthService.logout().then(() =>
                            window.location.reload()
                        ),
                },
            ]}
        />
    );
};
